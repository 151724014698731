import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon, CartIcon, ExpandedMoreIcon, ExpandMoreIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { getTestOrPackageDatailsApi, LabsAddToCartApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
const LabTestDetails = () => {
    const [DetailsData, setDetailsData] = useState<LabDetailsResponse | null>(null)
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const navigate = useNavigate();
    const location = useLocation();
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleCart = () => {
        navigate('/LabTests/Cart')
    }
    
    const handleExpansion = (index: number) => {
        setExpanded((prevExpanded) => ({
            [index]: !prevExpanded[index]
        }));
    };

    const fetchDetailsData = async (data: { type: number, id: number }) => {
        try {
            setLoading(true)
            const result = await getTestOrPackageDatailsApi(data);
            if (result?.success) {
                setLoading(false)
                setDetailsData(result?.result)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (location?.state?.id) {
            fetchDetailsData({ type: location?.state?.type, id: location?.state?.id })
        }
    }, [location.pathname])


    const handleAddToCart = async () => {
        try {
            if (LoginData?.loggedPatientId && location.state?.id) {
                const result = await LabsAddToCartApi(
                    {
                        type: location.state?.type,
                        referenceId: location.state?.id,
                        patientId: LoginData?.loggedPatientId,
                        mode: location.state?.mode
                    }
                )
                if (result?.success) {
                    toast.success(result?.message)
                    navigate('/LabTests/Cart')
                } else { toast.error(result?.message) }
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4'><BackIcon onClick={() => navigate(-1)} />{DetailsData?.nameOfTest}</h2>
                    <div className={styles.uploadPreandCartBtn}>
                        <button className='fillButton'>Upload Prescription</button>
                        <button className='outLineBtn' onClick={handleCart}><CartIcon />Cart</button>
                    </div>
                </div>
                <div className={styles.labtestDetails}>
                    <section className={`grid grid-cols-7 gap-6 ${styles.detailsLeftSec}`}>
                        <div className={`col-span-4 ${styles.testContent}`}>
                            <h4>{DetailsData?.nameOfTest}</h4>
                            <p>{DetailsData?.description}</p>
                        </div>
                        <div className={`col-span-3 ${styles.sampleColAndAddToCart}`}>
                            {
                                DetailsData?.modeOfTestOrPackage &&
                                <div className={`h-16 ${styles.sampleCollection}`}>
                                    <h4>Sample collection</h4>
                                    <p>{DetailsData?.modeOfTestOrPackage}</p>
                                </div>
                            }
                            <button className={`outLineBtn h-16`} onClick={handleAddToCart}> <CartIcon /> Add to Cart</button>
                        </div>
                    </section>
                    <section className='grid grid-cols-7 gap-6'>
                        <div className={`col-span-4 ${styles.parameter}`}>
                            <div className={styles.parameterHead}>
                                <h4>Test parameters</h4>
                            </div>
                            <div>
                                {
                                    DetailsData?.Tests?.map((item, index) => {
                                        const isExpanded = !!expanded[index];
                                        return (
                                            <Accordion
                                                expanded={isExpanded}
                                                onChange={() => handleExpansion(index)}
                                                className="CustomAccordion"
                                                key={index}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        isExpanded ?
                                                            <ExpandedMoreIcon />
                                                            : <ExpandMoreIcon />
                                                    }
                                                    aria-controls="helpContent"
                                                    id={`helpContent-${index}`}
                                                    className="AccordionSummary"
                                                >
                                                    <Typography className="AccordionHeader">{item?.nameOfTestsInPackages}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="accordionDetails">
                                                    {
                                                        item?.subTests?.map((subItem, id) => {
                                                            return (
                                                                <Typography key={id}>
                                                                    {subItem?.nameOfSubTests}
                                                                </Typography>
                                                            )
                                                        })
                                                    }

                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className='col-span-3'>
                            {
                                DetailsData?.requirementsBeforeTest &&
                                <div className={styles.requirementtestBox}>
                                    <h4>Requirements before the test</h4>
                                    <p>{DetailsData?.requirementsBeforeTest}</p>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </CustomContainer>
    )
}

export default LabTestDetails