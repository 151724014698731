import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { jwtDecode } from "jwt-decode";
import moment from 'moment';

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};
export const CustumTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};



export function convertUTCtoIST(date: Date): Date {
  let dateIST = new Date(date.getTime());
  const istOffset = 5.5 * 60 * 60 * 1000;
  dateIST = new Date(dateIST.getTime() + istOffset);
  return dateIST;
}

export const decodeToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};


export const handleDownloadFile = async (url: string, name?: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = name || "file";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error while fetching the file:", error);
  }
};
export const calculateAge = (dob: string): number => {
  const birthDate = moment(dob, 'DD-MM-YYYY');
  const currentDate = moment();
  return currentDate.diff(birthDate, 'years');
};

export const toTitleCase = (str: string) => {
  const exceptions = ['mChemist'];
  if (exceptions.includes(str)) {
    return str;
  }
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (char) => char.toUpperCase());
};


// device type start
export function getDeviceInfo(): string {
  const ua: string = navigator.userAgent;
  const deviceType: string = getDeviceType(ua);
  const browser: string = getBrowserInfo(ua);
  const os: string = getOSInfo(ua);

  return `${browser}, ${os}`;
}

function getDeviceType(ua: string): string {
  if (/mobile/i.test(ua)) {
      return 'Mobile';
  } else if (/tablet/i.test(ua)) {
      return 'Tablet';
  } else {
      return 'Desktop';
  }
}

function getBrowserInfo(ua: string): string {
  if (ua.includes("Firefox")) {
      return "Firefox";
  } else if (ua.includes("Chrome") && !ua.includes("Chromium")) {
      return "Chrome";
  } else if (ua.includes("Safari") && !ua.includes("Chrome") && !ua.includes("Chromium")) {
      return "Safari";
  } else if (ua.includes("Edge")) {
      return "Edge";
  } else if (ua.includes("MSIE") || ua.includes("Trident")) {
      return "Internet Explorer";
  } else if (ua.includes("Opera") || ua.includes("OPR")) {
      return "Opera";
  } else {
      return "Unknown Browser";
  }
}

function getOSInfo(ua: string): string {
  if (ua.includes("Win")) {
      if (ua.includes("Windows NT 10.0")) return "Windows 10";
      if (ua.includes("Windows NT 6.3")) return "Windows 8.1";
      if (ua.includes("Windows NT 6.2")) return "Windows 8";
      if (ua.includes("Windows NT 6.1")) return "Windows 7";
      if (ua.includes("Windows NT 6.0")) return "Windows Vista";
      if (ua.includes("Windows NT 5.1") || ua.includes("Windows XP")) return "Windows XP";
      return "Windows";
  } else if (ua.includes("Mac")) {
      return "Mac OS";
  } else if (ua.includes("Linux")) {
      return "Linux";
  } else if (ua.includes("Android")) {
      return "Android";
  } else if (ua.includes("like Mac")) {
      return "iOS";
  } else {
      return "Unknown OS";
  }
}

export function getFileExtension(filename: string): string | null {
  if (filename && filename.includes('.')) {
    return filename.split('.').pop()?.toLowerCase() || null;
  }
  return null;
}
// device type end