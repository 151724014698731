import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { FC } from 'react'
import styles from "../../assets/styles/medicalrecord.module.css"
import userIcon from "../../assets/images/team/1.png"
import { useNavigate } from 'react-router-dom'
import NoDataFound from '../common/NoDataFound'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../utils/store'

interface Props {
    BookedLabData?: BookedLabTest[] | []
}
const LabTestTable: FC<Props> = ({ BookedLabData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    
    const handleViewDetails = (e: React.MouseEvent, item: BookedLabTest) => {
        e.stopPropagation();
        navigate('/bookedLab/details', {
            state: {
                id: item?.labOrderId,
                cartId: item?.labId
            }
        })
    }

    const handleViewReport = (e: React.MouseEvent, item: BookedLabTest) => {
        e.stopPropagation();
        window.open(item?.labReportURL)
    }
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell>Lab Name</TableCell>
                        <TableCell >Packages/Tests</TableCell>
                        <TableCell className='whitespace-nowrap'>Date & Time</TableCell>
                        <TableCell>Type of test</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        BookedLabData && BookedLabData?.length > 0 ? <>
                            {
                                BookedLabData?.map((item, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className='custumTableRow cursor-pointer'
                                            title="View Details"
                                            onClick={(e) => handleViewDetails(e, item)}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className={styles.doctorSec}>
                                                    {/* <img src={userIcon} alt="user icon" /> */}
                                                    {item?.labName}
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item?.type}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <p>{item?.bookingDate}</p>
                                                <p>({item?.bookingTime})</p>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button className={`${styles.StatusBtn} ${styles.StatusBtnUpcoming}`}> {item?.mode}</button>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button
                                                    title={item?.labReportURL ? 'Click here to view report': ''}
                                                    className={`${styles.StatusBtn} ${item?.orderStatus === 'cancelled' ? styles.StatusBtnCancel : item?.orderStatus === 'pending' ? styles.StatusBtnUpcoming : ''}`}
                                                    onClick={(e) => item?.labReportURL && handleViewReport(e, item)}
                                                >{item?.orderStatus}</button>
                                            </TableCell>

                                        </TableRow>
                                    )
                                })
                            }
                        </>
                            :
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" colSpan={6}>
                                    <NoDataFound />
                                </TableCell>
                            </TableRow>
                    }


                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LabTestTable