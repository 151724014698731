import { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/teams.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import { BackIcon, SearchIcon } from '../../utils/functions/Icons'
import TeamCard from '../../components/careteams/TeamCard'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/common/Loader'
import NoDataFound from '../../components/common/NoDataFound'
import { useDispatch, useSelector } from 'react-redux'
import { CareTeamApiReducer, closeBookAppointment, openBookAppointment, resetDoctorSelection, toggleDoctorSelection } from '../../utils/store/careteam/CareTeamSlice'
import { AppDispatch, RootState } from '../../utils/store'
import {  createTeamApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../../components/common/BtnLoader'
import BookAppointmentModal from '../../components/myAppointments/BookAppointmentModal'

const CreateCareTeam = () => {
    const [searchValue, setSearchValue] = useState('')
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { CareTeamData, loading, SelectedDoctor, isModalOpenAppointment, particularData } = useSelector((state: RootState) => state.careteam)

    const handleCheck = (id: number) => {
        dispatch(toggleDoctorSelection(id));
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(CareTeamApiReducer({
                    id: 12345,
                    search: searchValue
                }))
            }, 1000);
            return () => clearTimeout(cleanFunction)
        }
        else {
            dispatch(CareTeamApiReducer({
                id: 12345,
            }))
        }
    }, [dispatch, searchValue]);

    const handleSubmit = async () => {
        try {
            const sendIds = SelectedDoctor?.map((item) => item?.id)
            setBtnLoading(true)
            const result = await createTeamApi({
                doctorIds: sendIds
            })
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                dispatch(resetDoctorSelection())
                navigate('/CareTeam')
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleCloseModal = () => {
        dispatch(closeBookAppointment());
    }
    const handleBook = async (data: CareTeamTypes) => {
        dispatch(openBookAppointment(data))
    }
    
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4'><BackIcon onClick={() => navigate(-1)} />Create Care team</h2>
                    <button
                        type='button'
                        className='fillButton'
                        onClick={handleSubmit}
                        disabled={btnLoading || SelectedDoctor?.length === 0}
                    >   {
                            btnLoading &&
                            <BtnLoader />
                        }Add to my Care team
                    </button>
                </div>
                <div className={styles.bannerSection}></div>
                <div className={styles.SearchBoxMain}>
                    <FormGroup className="customFormGroup">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search by doctor or specialising in'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                </div>
                {
                    loading ? <Loader /> :
                        <>
                            {
                                CareTeamData?.length > 0 ?
                                    <div className={styles.TeamsSection}>
                                        <div className={styles.selectedMember}>
                                            <h4>{SelectedDoctor?.length}/3 members are selected</h4>
                                        </div>
                                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
                                            {
                                                CareTeamData?.map((item, index) => {
                                                    return (
                                                        <TeamCard
                                                            key={index}
                                                            isCheckbox={true}
                                                            isChecked={item?.isChecked ? item?.isChecked : false}
                                                            id={item?.id}
                                                            handleCheck={handleCheck}
                                                            handleBook={handleBook}
                                                            item={item}
                                                            hideBook={true}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    : <NoDataFound />
                            }
                        </>
                }
            </div>
            <BookAppointmentModal
                isModalOpen={isModalOpenAppointment}
                handleCloseModal={handleCloseModal}
                particularData={particularData}
            />
        </CustomContainer>
    )
}

export default CreateCareTeam