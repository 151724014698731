import React, { ChangeEvent } from 'react';
import { FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { handleKeyPress } from "../../utils/functions/common";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { handleOpenModal } from '../../utils/store/profile/ProfileSlice';
import moment, { Moment } from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import styles from "../../assets/styles/profile.module.css";
import avtarIcon from "../../assets/images/common/avtar.png";
import AutocompleteInput from '../common/AutocompleteInput';


interface DependentFormProps {
    index: number;
    dependent: FormState;
    habits: { label: string; value: keyof HabitsState }[];
    handleAutoComplete: (name: keyof FormState, value: any, index?: number) => void;
    handleInputChange: HandleInputChangeType;
    handleSelectChange: HandleSelectChangeType;
    handleFileChange: (name: string, file: File | null, index?: number) => void;
    handleDependentChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
    handleDependentSelectChange: HandleSelectChangeDependentType;
    handleDependentDateChange: (date: Date, name: string, index: number) => void;
    RemoveProfileImg: (index?: number) => void;
}

const DependentForm: React.FC<DependentFormProps> = ({
    index,
    dependent,
    habits,
    handleAutoComplete,
    handleFileChange,
    handleDependentChange,
    handleDependentSelectChange,
    handleDependentDateChange,
    RemoveProfileImg
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { master, cityData, stateData } = useSelector((state: RootState) => state.profile);
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 md:gap-4 gap-y-4">
                <section className={styles.ProfileImageSec}>
                    <div className={styles.profileImg}>
                        <img src={dependent?.profile_image ? dependent?.profile_image : avtarIcon} alt='Patient Image' />
                    </div>
                    <div className={styles.chooseProfileAndRemove}>
                        <input
                            type="file"
                            name={'profile_image'}
                            id={`dependent${index}profile_image`}
                            accept={'.jpeg,.jpg,.png'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange('profile_image', e.target.files?.[0] ?? null, index)}
                            multiple={false}
                            style={{ display: 'none' }}
                        />
                        <label className={`cursor-pointer customFileInputLabel colorBorder`} htmlFor={`dependent${index}profile_image`}>
                            Choose profile
                        </label>
                        <h4 onClick={() => RemoveProfileImg(index)}>Remove</h4>
                    </div>
                </section>
                <section className={`col-span-3 ${styles.ProfileDetailsSec}`}>
                    <div className="grid grid-cols-3 gap-y-6 gap-x-16">
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                            <TextField
                                type='text'
                                className={`custumTextField profileInput`}
                                name="fullname"
                                required
                                placeholder='Enter your name'
                                value={dependent.fullname ?? ''}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Email Address <span className='errorMsg'>*</span></FormLabel>
                            <TextField
                                type='email'
                                className={`custumTextField profileInput`}
                                name="email"
                                required
                                placeholder='Enter your email'
                                value={dependent.email}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Mobile <span className='errorMsg'>*</span></FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="mobile"
                                required
                                placeholder='Enter your mobile'
                                value={dependent.mobile}
                                inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 10,
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Date of Birth <span className='errorMsg'>*</span></FormLabel>
                            <DatePicker
                                className="custumTextField profileInput"
                                value={moment(dependent.dateofbirth, 'DD-MM-YYYY')}
                                maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                format="DD-MM-YYYY"
                                onChange={(date) => handleDependentDateChange(moment(date)?.toDate(), "dateofbirth", index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Age</FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="age"
                                required
                                placeholder='Enter your age'
                                value={dependent.age}
                                inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 3,
                                }}
                                disabled
                                onKeyPress={handleKeyPress}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Gender <span className='errorMsg'>*</span></FormLabel>
                            <Select
                                className={`custumTextField profileInput`}
                                placeholder="Select gender"
                                value={dependent.gender}
                                name='gender'
                                onChange={(e) => handleDependentSelectChange(e, index)}
                            >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </Select>
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Blood Group</FormLabel>
                            <Select
                                className={`custumTextField profileInput`}
                                placeholder="Select blood group"
                                value={dependent.bloodgroup}
                                name='bloodgroup'
                                onChange={(e) => handleDependentSelectChange(e, index)}
                            >
                                <MenuItem value="A+">A+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="AB-">AB-</MenuItem>
                            </Select>
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Emergency Contact Number</FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="emergency_contact"
                                placeholder='Enter your mobile'
                                value={dependent.emergency_contact}
                                inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 10,
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Zip Code <span className='errorMsg'>*</span></FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="zip_code"
                                placeholder='Enter your zip code'
                                required
                                value={dependent?.zip_code}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">State <span className='errorMsg'>*</span></FormLabel>
                            <Select
                                className={`custumTextField profileInput`}
                                placeholder="Select state"
                                value={dependent?.state}
                                name='state'
                                onChange={(e) => handleDependentSelectChange(e, index)}
                            >
                                {
                                    stateData?.length > 0 && stateData?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">City <span className='errorMsg'>*</span></FormLabel>
                            <Select
                                className={`custumTextField profileInput`}
                                placeholder="Select city"
                                value={dependent?.city}
                                name='city'
                                disabled={dependent?.state !== "" ? false : true}
                                onChange={(e) => handleDependentSelectChange(e, index)}
                            >
                                {
                                    cityData?.length > 0 && cityData?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Height</FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="height"
                                placeholder='Enter height'
                                value={dependent.height}
                                InputProps={{
                                    inputMode: 'numeric',
                                    endAdornment: <InputAdornment position="end">in cm</InputAdornment>,
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Weight</FormLabel>
                            <TextField
                                type="text"
                                className={`custumTextField profileInput`}
                                name="weight"
                                placeholder='Enter weight'
                                value={dependent.weight}
                                InputProps={{
                                    inputMode: 'numeric',
                                    endAdornment: <InputAdornment position="end">in kg</InputAdornment>,
                                }}
                                onChange={(e) => handleDependentChange(e, index)}
                                onKeyPress={handleKeyPress}
                            />
                        </FormGroup>
                    </div>
                </section>
            </div>
            {
                Number(dependent.age) > 18 &&
                <div className={styles.otherInformation}>
                    <div className={styles.otherInfoHead}>
                        <h3>Other Information</h3>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6">
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">ABHA ID <span className={styles.dontHaveAbha}>(Don’t have Abha ID? <span onClick={() => dispatch(handleOpenModal())}>Create here</span>)</span></FormLabel>
                            <TextField
                                type='text'
                                className={`custumTextField profileInput w-full`}
                                name="abha_id"
                                placeholder='ABHA ID'
                                value={dependent.abha_id}
                                inputProps={{
                                    maxLength: 8,
                                    pattern: '[A-Za-z0-9]{8}',
                                }}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Current Medication</FormLabel>
                            <AutocompleteInput
                                options={master?.medications}
                                multiple
                                className="custumTextField profileInput"
                                value={dependent?.medications}
                                getOptionLabel={(option) => typeof option === 'string' ? option : option.medicationName}
                                getOptionKey={(option) => typeof option === 'string' ? option : option.medicationName}
                                createOption={(inputValue: string) => {
                                    return { categoryName: inputValue };
                                }}
                                onSelect={(value) => handleAutoComplete('medications', value, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Current Health problems</FormLabel>
                            <AutocompleteInput
                                options={master?.healthProblems}
                                multiple
                                className="custumTextField profileInput"
                                value={dependent?.health_problems}
                                getOptionLabel={(option) => typeof option === 'string' ? option : option.problemName}
                                getOptionKey={(option) => typeof option === 'string' ? option : option.problemName}
                                createOption={(inputValue: string) => {
                                    return { problemName: inputValue };
                                }}
                                onSelect={(value) => handleAutoComplete('health_problems', value, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Medical Allergies</FormLabel>
                            <AutocompleteInput
                                options={master?.allergies}
                                multiple
                                className="custumTextField profileInput"
                                value={dependent?.medical_allergies}
                                getOptionLabel={(option) => typeof option === 'string' ? option : option.allergiesName}
                                getOptionKey={(option) => typeof option === 'string' ? option : option.allergiesName}
                                createOption={(inputValue: string) => {
                                    return { allergiesName: inputValue };
                                }}
                                onSelect={(value) => handleAutoComplete('medical_allergies', value, index)}
                            />
                        </FormGroup>
                        <FormGroup className="customFormGroup">
                            <FormLabel className="customFormLabel">Do you have any past operation, hospitalization or accident? If Yes, please provide information</FormLabel>
                            <TextField
                                type='text'
                                className={`custumTextField profileInput`}
                                name="past_operation_detail"
                                value={dependent.past_operation_detail}
                                onChange={(e) => handleDependentChange(e, index)}
                            />
                        </FormGroup>
                    </div>
                    <div className={styles.otherInformation}>
                        <div className={styles.otherInfoHead}>
                            <h3>Social History</h3>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6">
                            <div className={styles.socialHistoryLeft}>
                                <FormControl component="fieldset" className='w-full'>
                                    <Table className='socialHistoryTable'>
                                        <TableBody>
                                            <TableRow className='socialHistoryTableHead'>
                                                <TableCell />
                                                <TableCell>
                                                    Past
                                                </TableCell>
                                                <TableCell>
                                                    Present
                                                </TableCell>
                                                <TableCell>
                                                    Never
                                                </TableCell>
                                            </TableRow>
                                            {habits.map((habit) => (
                                                <TableRow key={habit.value} className='socialHistoryTableRow'>
                                                    <TableCell>
                                                        {habit.label}
                                                    </TableCell>
                                                    <TableCell colSpan={3}>
                                                        <RadioGroup
                                                            row
                                                            className='justify-between'
                                                            name={`social_history.${habit.value}`}
                                                            value={dependent?.social_history[habit.value]}
                                                            onChange={(e) => handleDependentChange(e, index)}
                                                        >
                                                            <FormControlLabel
                                                                value="Past"
                                                                control={<Radio className='customRadio' />}
                                                                label=""
                                                            />
                                                            <FormControlLabel
                                                                value="Present"
                                                                control={<Radio className='customRadio' />}
                                                                label=""
                                                            />
                                                            <FormControlLabel
                                                                value="Never"
                                                                control={<Radio className='customRadio' />}
                                                                label=""
                                                            />
                                                        </RadioGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </FormControl>
                            </div>
                            <div className={styles.socialHistoryRight}>
                                <div className="grid grid-cols-2 gap-6">
                                    <FormGroup className="customFormGroup">
                                        <FormLabel className="customFormLabel">Marital Status</FormLabel>
                                        <Select
                                            className={`custumTextField profileInput`}
                                            placeholder="Marital Status"
                                            name='social_history.martial_status'
                                            value={dependent?.social_history?.martial_status}
                                            onChange={(e) => handleDependentSelectChange(e, index)}
                                        >
                                            <MenuItem value="single">Single</MenuItem>
                                            <MenuItem value="married">Married</MenuItem>
                                            <MenuItem value="divorced">Divorced</MenuItem>
                                            <MenuItem value="widowed">Widowed</MenuItem>
                                            <MenuItem value="separated">Separated</MenuItem>
                                        </Select>
                                    </FormGroup>
                                    <FormGroup className="customFormGroup">
                                        <FormLabel className="customFormLabel">Number of children</FormLabel>
                                        <TextField
                                            type="text"
                                            className={`custumTextField profileInput`}
                                            name="social_history.no_of_children"
                                            value={dependent?.social_history?.no_of_children}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                maxLength: 2,
                                            }}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e) => handleDependentChange(e, index)}

                                        />
                                    </FormGroup>
                                    <FormGroup className="customFormGroup col-span-2">
                                        <FormLabel className="customFormLabel">Job Description (If employeed)</FormLabel>
                                        <TextField
                                            type='text'
                                            className={`custumTextField profileInput`}
                                            name="social_history.job_description"
                                            value={dependent?.social_history?.job_description}
                                            onChange={(e) => handleDependentChange(e, index)}
                                        />
                                    </FormGroup>
                                    <FormGroup className="customFormGroup col-span-2">
                                        <FormLabel className="customFormLabel">Do you have any past exposure to the toxic substances?</FormLabel>
                                        <TextareaAutosize
                                            minRows={2}
                                            className={`custumTextField profileInput`}
                                            name='social_history.toxic_substance'
                                            value={dependent?.social_history?.toxic_substance}
                                            onChange={(e) => handleDependentChange(e, index)}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.otherInformation}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6'>
                            <div className={styles.otherInfoHead}>
                                <h3>Sexual & Emotional History</h3>
                            </div>
                            <div className={styles.otherInfoHead}>
                                <h3>OB-GYN History (Women only)</h3>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                            <div className={styles.emotionalHistoryBox}>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>Have you ever been treated for a sexually transmitted disease?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.sexual_transmitted_disease'
                                        value={dependent?.sexual_emotional_history?.sexual_transmitted_disease}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>Do you use condoms?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.use_condoms'
                                        value={dependent?.sexual_emotional_history?.use_condoms}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>What birth control method(s) do you use?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.birth_control'
                                        value={dependent?.sexual_emotional_history?.birth_control}
                                        onChange={(e) => handleDependentChange(e, index)}

                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>Have you ever been a victim of abuse?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.victim_abuse'
                                        value={dependent?.sexual_emotional_history?.victim_abuse}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                            <div className={styles.obgynHistoryBox}>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-1/3`}>Are you pregnant Now?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.is_pregnant'
                                        value={dependent?.sexual_emotional_history?.is_pregnant}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="Unsure"
                                            control={<Radio className='customRadio' />}
                                            label="Unsure"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                    <FormLabel className={`customFormLabel`}>If yes, due date :</FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="sexual_emotional_history.delivery_date"
                                        value={dependent?.sexual_emotional_history?.delivery_date}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    />
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                    <FormLabel className={`customFormLabel`}>No. of time Pregnant:</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="sexual_emotional_history.no_of_pregnancy"
                                        value={dependent?.sexual_emotional_history?.no_of_pregnancy}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 2,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    />
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-1/3`}>Type of Pregnancies</FormLabel>
                                    <RadioGroup
                                        row
                                        name='sexual_emotional_history.type_of_pregnancy'
                                        value={dependent?.sexual_emotional_history?.type_of_pregnancy}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Full-term"
                                            control={<Radio className='customRadio' />}
                                            label="Full-term"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="Miscarriage"
                                            control={<Radio className='customRadio' />}
                                            label="Miscarriage"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                    <FormLabel className={`customFormLabel`}>Date of last Menstrual Period</FormLabel>
                                    <DatePicker
                                        className={`custumTextField profileInput`}
                                        name='sexual_emotional_history.last_menstrual_period'
                                        value={moment(dependent?.sexual_emotional_history?.last_menstrual_period)}
                                        maxDate={moment(new Date())}
                                        onChange={(date) => handleDependentDateChange(moment(date)?.toDate(), "sexual_emotional_history.last_menstrual_period", index)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className={styles.otherInformation}>
                        <div className={styles.otherInfoHead}>
                            <h3>Symptoms all along</h3>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6'>
                            <FormGroup className="customFormGroup">
                                <FormLabel className={`customFormLabel`}>Please select the symptoms</FormLabel>
                                <AutocompleteInput
                                    options={master?.symptoms}
                                    multiple
                                    className="custumTextField profileInput"
                                    value={dependent?.symptoms}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.symptomsName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.symptomsName}
                                    createOption={(inputValue: string) => {
                                        return { symptomsName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('symptoms', value, index)}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className={`customFormLabel`}>Please select the Vaccination taken</FormLabel>
                                <AutocompleteInput
                                    options={master?.vaccinations}
                                    multiple
                                    className="custumTextField profileInput"
                                    value={dependent?.vaccinations}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.vaccinationName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.vaccinationName}
                                    createOption={(inputValue: string) => {
                                        return { vaccinationName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('vaccinations', value, index)}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className={styles.otherInformation}>
                        <div className={styles.otherInfoHead}>
                            <h3>Family History</h3>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6'>
                            {dependent.family_history?.length > 0 && dependent.family_history.map((item, findex) => (
                                <FormGroup key={findex} className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-1/3`}>
                                        {item.disease.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name={`family_history.${findex}.status`}
                                        className='w-1/3'
                                        value={item.status}
                                        onChange={(e) => handleDependentChange(e, index)}

                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                    <Select
                                        className={`custumTextField profileInput w-1/3`}
                                        displayEmpty
                                        name={`family_history.${findex}.relation`}
                                        value={item.relation}
                                        multiple
                                        onChange={(e) => handleDependentSelectChange(e, index)}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <MenuItem>Family Relation</MenuItem>;
                                            }
                                            return selected.map((value) => (
                                                master?.familyRelation?.find((r) => r.relationName === value)?.relationName
                                            )).join(', ');
                                        }}
                                    >
                                        <MenuItem value="" disabled selected>Family Relation</MenuItem>
                                        {master?.familyRelation.map((relation, idx) => (
                                            <MenuItem key={idx} value={relation.relationName}>
                                                {relation.relationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormGroup>
                            ))}
                        </div>
                    </div>

                    <div className={styles.otherInformation}>
                        <div className={styles.otherInfoHead}>
                            <h3>Family History</h3>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6'>
                            <div>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>Do you have an Advance Directive or Medical Power of Attorney? If yes, please list</FormLabel>
                                    <RadioGroup
                                        row
                                        name='consent.consent_attorney'
                                        value={dependent?.consent?.consent_attorney}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="consent.consent_list"
                                        value={dependent?.consent?.consent_list}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                    <FormLabel className={`customFormLabel w-2/3`}>Do you have any religious or spiritual beliefs you want your physician to know about?</FormLabel>
                                    <RadioGroup
                                        row
                                        name='religious_belief.consent_attorney'
                                        value={dependent?.religious_belief?.consent_attorney}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio className='customRadio' />}
                                            label="Yes"
                                            className='profileRadioLabel'
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio className='customRadio' />}
                                            label="No"
                                            className='profileRadioLabel'
                                        />
                                    </RadioGroup>
                                </FormGroup>
                                <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="religious_belief.consent_list"
                                        value={dependent?.religious_belief?.consent_list}
                                        onChange={(e) => handleDependentChange(e, index)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default DependentForm;